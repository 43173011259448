<template>
  <div style="background: #f2f2f2">
    <v-container class="py-15">
      <v-row justify="space-between" class="py-7">
        <div>
          <v-row no-gutters class="mx-6 mx-sm-0">
            <div
              class="filters"
              @click="filter = 0"
              :class="{
                'filters-mobile': $vuetify.breakpoint.smAndDown,
                'filters-active': filter == 0,
              }"
            >
              <v-row no-gutters class="align-center">
                <v-divider class="wrapped-line mr-2 mb-1"></v-divider>
                <div class="text font-16 font-weight-500 text-uppercase">
                  Alle
                </div>
              </v-row>
            </div>
            <div
              class="filters"
              @click="filter = 1"
              :class="{
                'filters-mobile': $vuetify.breakpoint.smAndDown,
                'filters-active': filter == 1,
              }"
            >
              <v-row no-gutters class="align-center">
                <v-divider class="wrapped-line mr-2 mb-1"></v-divider>
                <div class="text font-16 font-weight-500 text-uppercase">
                  Kapazität: 1-2 Personen
                </div>
              </v-row>
            </div>
            <div
              class="filters"
              @click="filter = 2"
              :class="{
                'filters-mobile': $vuetify.breakpoint.smAndDown,
                'filters-active': filter == 2,
              }"
            >
              <v-row no-gutters class="align-center">
                <v-divider class="wrapped-line mr-2 mb-1"></v-divider>
                <div class="text font-16 font-weight-500 text-uppercase">
                  Kapazität: 3-8 Personen
                </div>
              </v-row>
            </div>
            <div
              class="filters"
              @click="filter = 3"
              :class="{
                'filters-mobile': $vuetify.breakpoint.smAndDown,
                'filters-active': filter == 3,
              }"
            >
              <v-row no-gutters class="align-center">
                <v-divider class="wrapped-line mr-2 mb-1"></v-divider>
                <div class="text font-16 font-weight-500 text-uppercase">
                  Kapazität: 8+ Personen
                </div>
              </v-row>
            </div>
          </v-row>
        </div>
        <div class="mt-6 ml-6 mt-sm-0 ml-sm-0">
          {{ filteredProjects.length }} / {{ projects.length }}
        </div>
      </v-row>
      <v-row class="mx-md-n6 mx-2 mt-2 mt-md-10">
        <v-col
          class="col-12 col-sm-6 col-md-4"
          v-for="(project, ind) in filteredProjects"
          :key="project.id"
        >
          <ProjectCard :project="project" :ind="ind" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProjectCard from "./ProjectCard.vue";
import ProjectsMixin from "@/mixins/ProjectsMixin.js";

export default {
  components: {
    ProjectCard,
  },
  mixins: [ProjectsMixin],
  data: () => ({
    filter: 0,
    filteredProjects: [],
  }),
  mounted() {
    this.filteredProjects = [...this.projects];
  },
  watch: {
    filter: {
      handler() {
        if (this.filter == 0) {
          this.filteredProjects = [...this.projects];
        } else {
          this.filteredProjects = this.projects.filter(
            (el) => el.filter == this.filter
          );
        }
      },
    },
  },
};
</script>

<style lang="scss">
.filters {
  margin-right: 20px;
  cursor: pointer;
  &-mobile {
    margin-right: 100px;
    margin-top: 10px;
  }
  &-active {
    .wrapped-line {
      background: #f39300 !important;
      transform: rotate(30deg);
    }
    .text {
      color: #f39300 !important;
    }
  }
  .wrapped-line {
    width: 36px !important;
    background: #333333;
    transition: all 0.3s ease-out;
  }
  .text {
    color: #333333;
    transition: all 0.3s ease-out;
  }
}
.filters:hover {
  .wrapped-line {
    background: #f39300;
    transform: rotate(30deg);
  }
  .text {
    color: #f39300;
  }
}
</style>