<template>
    <AllProjectsComponent />
</template>

<script>
import AllProjectsComponent from '@/components/AllProjects/AllProjectsComponent.vue'

export default {
    components: {
        AllProjectsComponent
    }
}
</script>