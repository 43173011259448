<template>
  <div>
    <div
      class="background-main"
      :class="{ 'background-main-mobile': $vuetify.breakpoint.smAndDown }"
    >
      <v-img
        src="@/assets/images/background-image-all-projects.png"
        class="image"
        alt="background-img"
        :class="{ 'image-mobile': $vuetify.breakpoint.smAndDown }"
      />
      <div class="content">
        <v-container class="px-0">
          <v-row
            align="center"
            class="content-row"
            :class="{ 'content-row-mobile': $vuetify.breakpoint.smAndDown }"
          >
            <v-col
              class="col-12 col-md-8"
              :class="{ 'text-center px-7': $vuetify.breakpoint.smAndDown }"
            >
              <div class="font-48 font-weight-600 text-uppercase">
                <span class="text-color-orange">Unsere Projekte</span>
              </div>
              <div
                class="my-10 font-20 text-uppercase"
                :style="
                  !$vuetify.breakpoint.smAndDown ? 'margin-right: 250px' : ''
                "
              >
                Modulare Häuser: Innovative Lösungen und individueller Ansatz in
                den Projekten unseres Unternehmens
              </div>
            </v-col>
          </v-row>
        </v-container>
        <MovedArrow />
      </div>
    </div>

    <Header />
  </div>
</template>

<script>
import Header from "../UI/Header.vue";
import MovedArrow from "../UI/MovedArrow.vue";

export default {
  components: {
    Header,
    MovedArrow,
  },
};
</script>

<style scoped lang="scss">
.background-main {
  position: relative;
  min-height: 70vh !important;
  &-mobile {
    min-height: 100vh !important;
  }
  .image {
    width: 100vw !important;
    height: 75vh !important;
    object-fit: fill !important;
    &-mobile {
      min-height: 100vh !important;
    }
  }
  .image::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
  .content {
    position: absolute;
    top: 104px;
    left: 0;
    right: 0;
    bottom: 0px;
    color: #fff;
    &-row {
      height: calc(75vh - 104px) !important;
      &-mobile {
        height: calc(100vh - 170px) !important;
      }
    }
  }
}
</style>