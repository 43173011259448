<template>
    <div>
        <MainInfoBlock />
        <ProjectsBlock />
    </div>
</template>

<script>
import MainInfoBlock from "./MainInfoBlock.vue"
import ProjectsBlock from "./ProjectsBlock.vue"

export default {
    components: {
        MainInfoBlock,
        ProjectsBlock,
    },
    mounted() {
        window.scrollTo(0,0)
    }
}
</script>