var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#f2f2f2"}},[_c('v-container',{staticClass:"py-15"},[_c('v-row',{staticClass:"py-7",attrs:{"justify":"space-between"}},[_c('div',[_c('v-row',{staticClass:"mx-6 mx-sm-0",attrs:{"no-gutters":""}},[_c('div',{staticClass:"filters",class:{
              'filters-mobile': _vm.$vuetify.breakpoint.smAndDown,
              'filters-active': _vm.filter == 0,
            },on:{"click":function($event){_vm.filter = 0}}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-divider',{staticClass:"wrapped-line mr-2 mb-1"}),_c('div',{staticClass:"text font-16 font-weight-500 text-uppercase"},[_vm._v(" Alle ")])],1)],1),_c('div',{staticClass:"filters",class:{
              'filters-mobile': _vm.$vuetify.breakpoint.smAndDown,
              'filters-active': _vm.filter == 1,
            },on:{"click":function($event){_vm.filter = 1}}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-divider',{staticClass:"wrapped-line mr-2 mb-1"}),_c('div',{staticClass:"text font-16 font-weight-500 text-uppercase"},[_vm._v(" Kapazität: 1-2 Personen ")])],1)],1),_c('div',{staticClass:"filters",class:{
              'filters-mobile': _vm.$vuetify.breakpoint.smAndDown,
              'filters-active': _vm.filter == 2,
            },on:{"click":function($event){_vm.filter = 2}}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-divider',{staticClass:"wrapped-line mr-2 mb-1"}),_c('div',{staticClass:"text font-16 font-weight-500 text-uppercase"},[_vm._v(" Kapazität: 3-8 Personen ")])],1)],1),_c('div',{staticClass:"filters",class:{
              'filters-mobile': _vm.$vuetify.breakpoint.smAndDown,
              'filters-active': _vm.filter == 3,
            },on:{"click":function($event){_vm.filter = 3}}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-divider',{staticClass:"wrapped-line mr-2 mb-1"}),_c('div',{staticClass:"text font-16 font-weight-500 text-uppercase"},[_vm._v(" Kapazität: 8+ Personen ")])],1)],1)])],1),_c('div',{staticClass:"mt-6 ml-6 mt-sm-0 ml-sm-0"},[_vm._v(" "+_vm._s(_vm.filteredProjects.length)+" / "+_vm._s(_vm.projects.length)+" ")])]),_c('v-row',{staticClass:"mx-md-n6 mx-2 mt-2 mt-md-10"},_vm._l((_vm.filteredProjects),function(project,ind){return _c('v-col',{key:project.id,staticClass:"col-12 col-sm-6 col-md-4"},[_c('ProjectCard',{attrs:{"project":project,"ind":ind}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }