<template>
  <div class="wrapper-images">
    <v-img :src="require('@/assets/images/projects/' + project.folder + '/' + project.images[0])" class="anim-image" />
    <figcaption class="d-context text-color-white font-24">
      <div class="id-num">
        <span v-if="ind < 10">0</span>{{ ind + 1 }}
      </div>
      <div class="text-uppercase">{{ project.title }}</div>
      <v-divider color="#F39300" class="line mt-2" />
    </figcaption>
    <figcaption class="h-content">
      <v-row no-gutters class="mt-3 align-center">
        <div>
          <img src="@/assets/images/gebiet-icon.svg" />
        </div>
        <div class="ml-3">
          <div class="text-color-white">Gebiet:</div>
          <div class="text-color-orange">
            {{ project.square }} m<sup>2</sup>
          </div>
        </div>
      </v-row>
      <v-row no-gutters class="mt-4 align-center">
        <div>
          <img src="@/assets/images/kapazitat-icon.svg" />
        </div>
        <div class="ml-3">
          <div class="text-color-white">Kapazität:</div>
          <div class="text-color-orange">{{ project.capacity }}</div>
        </div>
      </v-row>
    </figcaption>
    <figcaption class="h-btn">
      <v-btn
        @click="$router.push(`/project/${project.id}`)"
        class="btn-anim text-transform-none"
        outlined
      >
        <div class="icon">
          <v-icon color="#f2f2f2">mdi-arrow-right</v-icon>
        </div>
        <div class="content-text">Mehr Lesen</div>
      </v-btn>
    </figcaption>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      require: true,
    },
    ind: {
      require: true,
    }
  },
};
</script>

<style scoped lang="scss">
.wrapper-images {
  position: relative !important;
  overflow: hidden;
  &:hover .h-content {
    left: 20px;
  }
  &:hover .line {
    width: 100px !important;
    border: 0.5px solid #f39300;
  }
  &:hover .id-num {
    color: #f39300;
  }
  &:hover .anim-image {
    scale: 1.2;
    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
  }
  &:hover .h-btn {
    bottom: 20px;
  }
}
.anim-image {
  height: 350px;
  width: 100% !important;
  object-fit: cover;
  transition: all 0.3s ease-out;
  &::after {
    position: absolute;
    content: "";
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
}
.d-context {
  position: absolute;
  top: 30px;
  left: 20px;
  .line {
    width: 0px;
    transition: all 0.3s ease-out;
  }
  .id-num {
    transition: all 0.3s ease-out;
  }
}
.h-content {
  position: absolute;
  top: 120px;
  left: -200px;
  transition: all 0.3s ease-out;
}
.h-btn {
  position: absolute;
  bottom: -50px;
  left: calc(50% - 96px);
  transition: all 0.3s ease-out;
}
.btn-anim {
  width: 192px;
  height: 48px !important;
  background: rgba(242, 242, 242, 0.1);
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease-out;

  .icon {
    margin-left: -60px;
    transition: all 0.2s ease-out 0s;
  }
  .content-text {
    margin-left: 30px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover {
    background: linear-gradient(180deg, #1b1b1b 0%, #1b1b1b 200%), #f2f2f2;
    border: 2px solid #1b1b1b;
  }
  &:hover .icon {
    margin-left: 145px;
    transition: all 0.2s ease-out 0.1s;
  }
  &:hover .content-text {
    margin-top: 100px;
    transition: all 0.2s ease-out 0s;
  }
}
</style>